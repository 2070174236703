import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { Header, Icon, Transition } from 'semantic-ui-react'

import { RECORD_FILTER_FORM_ID } from 'assets/constants/forms'
import { paths } from 'assets/constants/navigation'
import { eventCodes } from 'assets/constants/notifications'
import { INIT_PAGE_SIZE } from 'assets/constants/pagination'
import { dateToStr, openLinkInNewTab } from 'assets/utils/common'
import RecordFilterForm from 'components/RecordFilterForm/RecordFilterForm'
import BasicButton from 'components/UI/BasicButton/BasicButton'
import CompactTable from 'components/UI/CompactTable/CompactTable'
import InfoMessage from 'components/UI/InfoMessage/InfoMessage'
import PageTitle from 'components/UI/PageTitle/PageTitle'
import PrimaryButton from 'components/UI/PrimaryButton/PrimaryButton'
import SecondaryButton from 'components/UI/SecondaryButton/SecondaryButton'
import TableFooterPagination from 'components/UI/TableFooterPagination/TableFooterPagination'
import useDialog from 'hooks/useDialog'
import useLoader from 'hooks/useLoader'
import useNotifications from 'hooks/useNotifications'
import usePagination from 'hooks/usePagination'
import recordsService from 'services/records'
import styles from './Records.module.scss'


const Records = () => {
    const [formattedRows, setFormattedRows] = useState(null)
    const [pageSize, setPageSize] = useState(INIT_PAGE_SIZE)
    const [isFilterMenuOpen, setFilterMenuOpen] = useState(false)
    const { openErrorDialog, closeDialog } = useDialog()
    const { showLoader, hideLoader } = useLoader()
    const navigate = useNavigate()
    const { receivedEvent, eventNumber } = useNotifications()
    const [recordsData, setRecordsData] = usePagination()
    const currentPageRef = useRef()
    const pageSizeRef = useRef()

    const initialFilterData = {
        partners: null,
        insureds: null,
        record_types: null,
        from_date: null,
        until_date: null,
        status: null,
    }

    const [filterData, setFilterData] = useState(initialFilterData)

    const { currentPage, lastPage, nextPage, previousPage, results, total } = recordsData
    currentPageRef.current = currentPage
    pageSizeRef.current = pageSize

    const columns = [
        { content: 'Registro', width: 2 },
        { content: 'Fecha', width: 2 },
        { content: 'Asociado', width: 4 },
        { content: 'Asegurado', width: 4 },
        { content: 'Tipo', width: 2 },
        { content: 'Estado', width: 2 },
    ]

    const fetchRecords = (params) => {
        const { avoidLoader, page, size, filters } = params || {}

        !avoidLoader && showLoader('Cargando expedientes...')

        recordsService.getRecords(
            (page || currentPageRef.current || 1),
            (size || pageSizeRef.current || INIT_PAGE_SIZE),
            filters || filterData
        )
            .then((res) => {
                const rows = res.data.results.map((result) => ({
                    id: result.id,
                    onClick: () => {
                        const path = paths.RECORD_MANAGEMENT.replace(':recordId', result.id)
                        openLinkInNewTab(`${window.location.origin}${path}`)
                    },
                    cells: [
                        { content: <strong>{result.number || '-'}</strong> },
                        { content: dateToStr(result.entry_date) || '-' },
                        { content: result.partner?.name || '-' },
                        { content: result.insured?.name || '-' },
                        { content: result.type?.name || '-' },
                        { content: result.closed ? 'Cerrado' : 'Abierto' },
                    ],
                }))
                setRecordsData(res.data)
                setFormattedRows(rows)
                size && setPageSize(size)

                !avoidLoader && hideLoader()
            })
            .catch((error) => {
                !avoidLoader && hideLoader()

                // 404 can happen due to wrong page number for high page sizes,
                // so we simply show the first page for the given page size, but
                // if we have a 404 after trying this we must show an error
                if ((error.response.status === 404) && (page !== 1)) {
                    fetchRecords({ page: 1, size })
                    return
                }

                let errorMessage = error?.response?.data?.error

                if (!errorMessage) {
                    errorMessage = `Ha tenido lugar un error al cargar los expedientes.
                                    Por favor, inténtalo de nuevo más tarde o
                                    contacta con el administrador de la plataforma.`
                }

                openErrorDialog({
                    title: 'Error al cargar datos',
                    content: errorMessage,
                    size: 'tiny',
                    onConfirm: () => {
                        navigate(paths.RECORDS)
                        closeDialog()
                    }
                })
            })
    }

    const areFiltersSet = useMemo(() => {
        for (let key in filterData) {
            const value = filterData[key]

            if ((Array.isArray(value) && (value.length > 0)) || (!Array.isArray(value) && Boolean(value))) {
                return true
            }
        }
        return false
    }, [filterData])

    useEffect(() => {
        fetchRecords()
    }, [filterData])

    useEffect(() => {
        const relevantEvents = [
            eventCodes.RECORD_CREATED,
            eventCodes.RECORD_DELETED,
            eventCodes.RECORD_UPDATED,
        ]

        if (relevantEvents.includes(receivedEvent?.code)) {
            fetchRecords({ avoidLoader: true })
        }
    }, [receivedEvent?.code, eventNumber])

    return (
        <>
            <Helmet>
                <title>Expedientes - CenterIuris</title>
            </Helmet>
            <PageTitle
                hasWideIcon
                icon='balance scale'
                header='Expedientes'
            />
            <article className={styles.Content}>
                <div className={styles.MainPanel}>
                    <header className={styles.OptionsBar}>
                        <BasicButton
                            compact
                            icon
                            color='darkgreen'
                            labelPosition='right'
                            className={styles.CreateButton}
                            onClick={() => navigate(paths.NEW_RECORD)}
                        >
                            <Icon name='plus circle' />
                            Crear nuevo
                        </BasicButton>
                        {
                            (areFiltersSet || (results?.length > 0)) &&
                            <BasicButton
                                compact
                                icon
                                color='darkgreen'
                                labelPosition='right'
                                onClick={() => setFilterMenuOpen(!isFilterMenuOpen)}
                            >
                                <Icon name='filter' />
                                {isFilterMenuOpen ? 'Ocultar' : 'Mostrar'} filtros
                            </BasicButton>
                        }
                    </header>
                    <Transition.Group animation='slide down' duration={250}>
                        {
                            isFilterMenuOpen &&
                            <div className={styles.FilterMenu}>
                                <Header dividing className={styles.MenuTitle}>
                                    Filtros
                                </Header>
                                <RecordFilterForm
                                    initialValues={filterData}
                                    onSubmit={(data) => setFilterData(data)}
                                />
                                <div className={styles.FilterButtons}>
                                    <PrimaryButton compact type='submit' form={RECORD_FILTER_FORM_ID}>
                                        Aplicar filtros
                                    </PrimaryButton>
                                    {
                                        areFiltersSet &&
                                        <SecondaryButton compact onClick={() => setFilterData(initialFilterData)}>
                                            Restablecer
                                        </SecondaryButton>
                                    }
                                </div>
                            </div>
                        }
                    </Transition.Group>
                    <div className={`${styles.DataWrapper}${(results?.length === 0) ? ` ${styles.NoData}` : ''}`}>
                        {
                            (results?.length > 0) &&
                            <>
                                <CompactTable
                                    className={styles.RecordsTable}
                                    columns={columns}
                                    rows={formattedRows}
                                    noResultsRow={{
                                        className: styles.NoResultsRow,
                                        cell: {
                                            className: styles.NoResultsCell,
                                            content: (
                                                <strong><i>El filtro no ha devuelto ningún expediente</i></strong>
                                            )
                                        },
                                    }}
                                />
                                <div className={styles.PaginationManagement}>
                                    <TableFooterPagination
                                        currentPage={currentPage}
                                        lastPage={lastPage}
                                        previousPage={previousPage}
                                        nextPage={nextPage}
                                        pageSize={pageSize}
                                        totalItems={total}
                                        pageItems={results?.length}
                                        onPageChange={(page) => fetchRecords({ page })}
                                        onPageSizeChange={(size) => fetchRecords({ size })}
                                    />
                                </div>
                            </>
                        }
                        {
                            (results?.length === 0) &&
                            <InfoMessage
                                className={styles.NoItemsMessage}
                                title={`No hay ${areFiltersSet ? 'coincidencias' : 'expedientes'}`}
                                content={
                                    areFiltersSet
                                        ? 'El filtro no ha devuelto ningún expediente'
                                        : 'Actualmente no hay ningún expediente registrado'
                                }
                            />
                        }
                    </div>
                </div>
            </article>
        </>
    )
}

export default Records